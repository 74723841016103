<div class="di_ov">
    <div class="ban_abt">
        <div class="abt_txt">
            <h3>Lorem ipsum dolor <br> sit amet, consetetur</h3>
        </div>
        <div class="img_abt"><img src="assets/imagenesWeb/About/a_header.png" width="100%" alt="..."></div>
    </div>
</div>
<div class="cont-text">
    <br>
    <h5>Publicado el 00/00/0000</h5>
    <div>
        <h6>Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum qui assumenda, labore fugit consequuntur excepturi voluptatum, omnis nostrum nisi reiciendis, maxime quis eius! Iure quo unde accusamus assumenda, illo sint. Lorem ipsum dolor sit
            amet consectetur eaque</h6>
        <br>
        <div class="rw-one">
            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Corporis reiciendis soluta laudantium quasi nemo recusandae vel reprehenderit excepturi, ducimus inventore, tempora optio, officia exercitationem natus velit laborum? Tenetur, voluptatem
                qui! Lorem ipsum dolor sit amet, <br><br> consectetur adipisicing elit. Vitae ipsum velit doloribus iure quas odio eligendi voluptatibus. Dicta minima nam doloribus vel nemo explicabo odit quisquam, voluptatum sint sapiente sunt!</p>
            <img src="assets/6522089_orig.jpg" alt="">
        </div>
        <br>
        <div class="rw-two">
            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Harum nisi doloremque eius tenetur, quisquam eveniet facilis aut nesciunt cum quas deleniti quod maxime voluptate quaerat accusantium, nam vel hic nostrum? Lorem ipsum dolor sit amet
                consectetur, adipisicing elit. <br><br> Expedita, cumque voluptatum praesentium impedit veritatis aliquam odio soluta ratione quasi atque optio vitae delectus facere, enim cum fugiat, maiores dicta magnam!</p>
            <img src="assets/6522089_orig.jpg" alt="">
        </div>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam aliquid vitae, necessitatibus excepturi quas labore nostrum aliquam. Dolorum architecto magni odit natus eius et repellat? Qui quaerat officiis facere porro.l Lorem ipsum, dolor
            sit amet consectetur adipisicing elit. Voluptate exercitationem alias eligendi error corrupti commodi sapiente, voluptates <br><br> fugiat consequuntur. Lorem ipsum dolor sit amet consectetur adipisicing elit. Et nihil ex consequuntur, possimus
            reprehenderit tempore alias voluptas aliquid maiores assumenda exercitationem blanditiis error odio autem molestiae, recusandae mollitia eos eum! Inventore velit deserunt temporibus cumque pariatur, tenetur debitis labore harum? Qui?</p>
    </div>
</div>

<div class="ban_glo">
    <button type="button" class="btn_bred" routerLink="/contact">
        <div class="btn_fle">
            <div class="txt_fle">Contact us</div>
            <div class="wht_sha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta.svg" width="30px" height="30px"
                    alt="..."></div>
            <div class="redsha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta-white.svg" width="30px"
                    height="30px" alt="..."></div>
        </div>
    </button>
    <img src="assets/imagenesWeb/Banners/banner_4.png" width="100%" alt="...">
</div>