<app-menu-wrapper></app-menu-wrapper>
<div class="ban_abt">
    <div class="abt_txt">
        <h3>White-Label <br> Social Media</h3>
        <p>Scale your costs and revenue going nearshore</p>
        <button type="button" class="btn_bred" routerLink="/contact">
            <div class="btn_fle">
                <div class="txt_fle">Book a call</div>
                <div class="wht_sha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta-white.svg" width="30px"
                        height="30px" alt="..."></div>
                <div class="redsha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta.svg" width="30px" height="30px"
                        alt="..."></div>
            </div>
        </button>
    </div>
    <div class="img_abt"><img src="assets/imagenesWeb/Service/Services - Social media/s_header_socialmedia.png" width="100%" alt="..."></div>
</div>
<div class="heart_pic ">
    <div class="ul_heart_pic">
        <h3 class="page-title">
            Social Media <br> Management <br> Services
        </h3>
        <p class="display-text">
            We help your company to build a nearshore <br> social media team so you can scale your costs. <br> Send us the job requirements and we would seek <br> and hire the right talent for your company.
            <br>
            <span>Our nearshore talent usually have:</span>
        </p>
        <ul>
            <li>
                <span>Technical English skills</span>
            </li>
            <li>
                <span> Experience in social media</span>
            </li>
            <li><span>High sense of responsability</span>
            </li>
            <li>
                <span>Tech savvy and computer skills</span>
            </li>
            <li>
                <span>Full access to internet</span>
            </li>
        </ul>
        <button type="button" class="btn_bred" routerLink="/contact">
            <div class="btn_fle">
                <div class="txt_fle">Request a quote</div>
                <div class="wht_sha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta-white.svg" width="30px"
                        height="30px" alt="..."></div>
                <div class="redsha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta.svg" width="30px" height="30px"
                        alt="..."></div>
            </div>
        </button>
    </div>
    <img src="assets/imagenesWeb/Service/Services - Social media/s_socialme services.png" width="100%" alt="...">

</div>
<div class="o_exp">
    <br>
    <h2>White-Label solutions <br> we offer to your company</h2>
    <div id="carouseldesk" class="carousel slide carousel-dark" data-bs-ride="carousel">
        <div class="carousel-inner">
            <div class="carousel-item active">
                <div class="card_fle">
                    <div class="card">
                        <div class="gro_wh">
                            <img src="assets/iconosWeb/WLNIconos/copy writing - wln.svg" class="card-img-top" alt="...">
                        </div>
                        <div class="card-body">
                            <h4>Copy writing and content management</h4>
                        </div>
                    </div>
                    <div class="card">
                        <div class="gro_wh">
                            <img src="assets/iconosWeb/WLNIconos/social media - wln.svg" class="card-img-top" alt="...">
                        </div>
                        <div class="card-body">
                            <h4>Social media / Digital marketing specialist</h4>
                        </div>
                    </div>
                    <div class="card">
                        <div class="gro_wh">
                            <img src="assets/iconosWeb/WLNIconos/graphic design - wln.svg" style="transform: rotate(55deg);" alt="..." />
                        </div>
                        <div class="card-body">
                            <h4>Graphic design and creatives</h4>
                        </div>
                    </div>
                    <div class="card">
                        <div class="gro_wh">
                            <img src="assets/iconosWeb/WLNIconos/ads management - wln.svg" alt="..." />
                        </div>
                        <div class="card-body">
                            <h4>Ads management</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-item">
                <div class="card_fle">
                    <div class="card">
                        <div class="gro_wh">
                            <img src="assets/iconosWeb/WLNIconos/social community - wln.svg" class="card-img-top" alt="...">
                        </div>
                        <div class="card-body">
                            <h4>Social Community management and support: inbox/DM/Ads/newsfeed wall</h4>
                        </div>
                    </div>
                    <div class="card">
                        <div class="gro_wh">
                            <img src="assets/iconosWeb/WLNIconos/reports and analytics - wln.svg" class="card-img-top" alt="...">
                        </div>
                        <div class="card-body">
                            <h4>Reports and analytics</h4>
                        </div>
                    </div>
                    <div class="card">
                        <div class="gro_wh">
                            <img src="assets/iconosWeb/WLNIconos/social listening - wln.svg" alt="..." />
                        </div>
                        <div class="card-body">
                            <h4>Social Listening and benchmarking</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouseldesk" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouseldesk" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
    </div>
    <div id="carouselmob" class="carousel slide carousel-dark" data-bs-ride="carousel">
        <div class="carousel-inner">
            <div class="carousel-item active">
                <div class="card_fle">
                    <div class="card">
                        <div class="gro_wh">
                            <img src="assets/iconosWeb/WLNIconos/copy writing - wln.svg" class="card-img-top" alt="...">
                        </div>
                        <div class="card-body">
                            <h4>Copy writing and content management</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-item">
                <div class="card_fle">
                    <div class="card">
                        <div class="gro_wh">
                            <img src="assets/iconosWeb/WLNIconos/social media - wln.svg" class="card-img-top" alt="...">
                        </div>
                        <div class="card-body">
                            <h4>Social media / Digital marketing specialist</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-item">
                <div class="card_fle">
                    <div class="card">
                        <div class="gro_wh">
                            <img src="assets/iconosWeb/WLNIconos/graphic design - wln.svg" style="transform: rotate(55deg);" alt="..." />
                        </div>
                        <div class="card-body">
                            <h4>Graphic design and creatives</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-item">
                <div class="card_fle">
                    <div class="card">
                        <div class="gro_wh">
                            <img src="assets/iconosWeb/WLNIconos/ads management - wln.svg" alt="..." />
                        </div>
                        <div class="card-body">
                            <h4>Ads management</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-item">
                <div class="card_fle">
                    <div class="card">
                        <div class="gro_wh">
                            <img src="assets/iconosWeb/WLNIconos/social community - wln.svg" class="card-img-top" alt="...">
                        </div>
                        <div class="card-body">
                            <h4>Social Community management and support: inbox/DM/Ads/newsfeed wall</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-item">
                <div class="card_fle">
                    <div class="card">
                        <div class="gro_wh">
                            <img src="assets/iconosWeb/WLNIconos/reports and analytics - wln.svg" class="card-img-top" alt="...">
                        </div>
                        <div class="card-body">
                            <h4>Reports and analytics</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-item">
                <div class="card_fle">
                    <div class="card">
                        <div class="gro_wh">
                            <img src="assets/iconosWeb/WLNIconos/social listening - wln.svg" alt="..." />
                        </div>
                        <div class="card-body">
                            <h4>Social Listening and benchmarking</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselmob" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselmob" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
    </div>
    <br><br> <br><br>
</div>
<div class="ban_lov">
    <button type="button" class="btn_bred" routerLink="/about-whitelabel-nearshore">
        <div class="btn_fle">
            <div class="txt_fle">Learn more</div>
            <div class="wht_sha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta.svg" width="30px" height="30px"
                    alt="..."></div>
            <div class="redsha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta-white.svg" width="30px"
                    height="30px" alt="..."></div>
        </div>
    </button>
    <img src="assets/imagenesWeb/Banners/banner_5.png" width="100%" alt="...">
</div>

<h1 class= "wdn-text-hidden"> White Label Nearshore Social Media</h1>

<div class="team_txt">
    <div class="txt_team_lef">
        <h4>
            Why build <br> a nearshore
        </h4>
        <h3 class="page-title">
            social <br> media team <br> <span>?</span>
        </h3>
    </div>
    <div class="ul_team">
        <p class="display-text">
            It has been proven that going nearshore provides you with <br> great benefits such as scaling your costs and scaling your <br> revenue, not to mention having a long-term nearshore <br> partner. You’ll get the same quality that an onshore team
            can
            <br> provide at an affordable cost.
            <br><br>
            <span>Our value proposition with your company is:</span>

        </p>
        <ul>
            <li>
                <span>Scale your offering</span>
            </li>
            <li>
                <span>Scale your costs</span>
            </li>
            <li><span>Scale your revenue</span>
            </li>
            <li>
                <span>Be you long-term nearshore partner</span>
            </li>
        </ul>
        <br>
        <button type="button" class="btn_bred" routerLink="/contact">
            <div class="btn_fle">
                <div class="txt_fle">Book a Call</div>
                <div class="wht_sha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta-white.svg" width="30px"
                        height="30px" alt="..."></div>
                <div class="redsha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta.svg" width="30px" height="30px"
                        alt="..."></div>
            </div>
        </button>
    </div>
    <img class="pic_gosht" src="assets/imagenesWeb/Home/h_why.png" width="100%" alt="...">

</div>
<div class="ban_glo">
    <button type="button" class="btn_bred" routerLink="/contact">
        <div class="btn_fle">
            <div class="txt_fle">Contact us</div>
            <div class="wht_sha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta.svg" width="30px" height="30px"
                    alt="..."></div>
            <div class="redsha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta-white.svg" width="30px"
                    height="30px" alt="..."></div>
        </div>
    </button>
    <img src="assets/imagenesWeb/Banners/banner_4.png" width="100%" alt="...">
</div>