<br>
<br>
<div class="form-contact ">
  <h1>
    Contact us <br> <span>today!</span>
  </h1>
  <br>
  <h5>Fill the form and our sales team will contact you shortly</h5>
</div>
<h1 class="wdn-text-hidden"> Contact White Label Nearshore</h1>

<form #contactForm="ngForm" (ngSubmit)="sendContact()">

  <div class="rwcaont">
    <div class="clm-left">
      <div class="in-left">
        <label for="name" class="form-label">Name</label>
        <input type="text" name="name" class="form-control" id="Name" [(ngModel)]="contact.name" #name="ngModel"
          required autocomplete="name">
      </div>
      <div class="in-left">
        <label for="lastName" class="form-label">Last name</label>
        <input type="text" name="lastName" class="form-control" id="lastName" [(ngModel)]="contact.lastName"
          #lastName="ngModel" required autocomplete="lastName">
      </div>
      <div class="in-left">
        <label for="email" class="form-label">E-mail</label>
        <input type="email" name="email" id="email" class="form-control" [(ngModel)]="contact.email" #email="ngModel"
          required autocomplete="email">
      </div>
      <div class="in-left">
        <label for="phoneNumber" class="form-label">Phone number</label>
        <input type="text" name="phoneNumber" class="form-control" id="phoneNumber" [(ngModel)]="contact.phoneNumber"
          #phoneNumber="ngModel" required autocomplete="phoneNumber">
      </div>
      <div class="in-left">
        <label for="company" class="form-label">Company</label>
        <input type="text" name="company" class="form-control" id="company" [(ngModel)]="contact.company"
          #company="ngModel" required autocomplete="company">
      </div>
      <div class="in-left">
        <label for="country" class="form-label">Country</label>
        <input type="text" name="country" class="form-control" id="country" [(ngModel)]="contact.country"
          #country="ngModel" required autocomplete="country">
      </div>
      <div class="in-left">
        <label for="state" class="form-label">State</label>
        <input type="text" name="state" class="form-control" id="state" [(ngModel)]="contact.state" #state="ngModel"
          required autocomplete="state">
      </div>
      <div class=" small-data">
        <div class="in-left">
          <label for="city" class="form-label">City</label>
          <input type="text" name="city" id="city" class="form-control" [(ngModel)]="contact.city" #city="ngModel"
            required autocomplete="city">
        </div>
        <div class="in-left">
          <label for="zipCode" class="form-label">Zip</label>
          <input type="text" name="zipCode" class="form-control" id="zipCode" [(ngModel)]="contact.zipCode"
            #zipCode="ngModel" required autocomplete="zipCode">
        </div>
      </div>
    </div>
    <div class="clm-right">
      <div class=" mesage">
        <label for="message" class="form-label">Message</label>
        <textarea class="form-control" id="message" name="message" aria-label="With textarea"
          [(ngModel)]="contact.message" #message="ngModel"></textarea>
      </div>
      <div class="form-check">
        <div class="col-lg-6">
          <label for="send-copy" class="form-check-label content-text">
            Send me a copy?
          </label>
          <input type="checkbox" name="send-copy" class="form-check-input" id="send-copy" [(ngModel)]="contact.resend"
            #resend="ngModel">
        </div>
      </div>
      <br>
      <re-captcha (resolved)="resolved($event)" siteKey="6Le_uy0pAAAAAFasEp-zJJpBex31rK6rjmw5q9Xx" ></re-captcha>
      <br>
      <button type="submit" class="btnsend" [disabled]="isSending || !validated">Send</button>
    </div>
  </div>
</form>

<br><br>
<div class="real-map">
  <div class="rw-map">
    <div class="txt-map">
      <h1>
        White-label <br> <span>Nearshore</span>
      </h1>
      <a href="https://api.whatsapp.com/send?phone=50242495407&text=Hi">
        <h5><i><img src="assets/iconosWeb/WLNIconos/red_icon/social media - wln.svg" width="30px" height="30px"
              alt="..."></i> &nbsp;+502 4249-5407</h5>
      </a>
      <a href="tel:+50222957260">
        <h5> <i><img src="assets/iconosWeb/WLNIconos/red_icon/social media - wln.svg" width="30px" height="30px"
              alt="..."></i> &nbsp;+502 2295 7260</h5>
      </a>
      <h5><i><img src="assets/iconosWeb/WLNIconos/red_icon/mail - wln.svg" width="30px" height="30px" alt="..."></i>
        &nbsp;sales@whitelabelnearshore.com</h5>
      <h5><i><img src="assets/iconosWeb/WLNIconos/red_icon/alfiler.svg" width="30px" height="30px" alt="..."></i>
        &nbsp;14 avenue 14-93 zone 10, Guatemala City, Guatemala, CA 01010</h5>
      <h5><i><img src="assets/iconosWeb/WLNIconos/red_icon/reloj-de-pared.svg" width="30px" height="30px" alt="..."></i>
        &nbsp;Mon - Fri 8 am to 6 pm EST</h5>
    </div>
    <div class="img-map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15444.243047715428!2d-90.5018946!3d14.5956135!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7753a4083a0ef2cf!2sHuman%20Brands!5e0!3m2!1ses-419!2sgt!4v1638197096514!5m2!1ses-419!2sgt"
        style="border:0;" allowfullscreen="" loading="lazy"></iframe>
    </div>
  </div>
</div>
<div class="banspace">
  <button type="button" class="btn_bred" routerLink="/solutions">
    <div class="btn_fle">
      <div class="txt_fle">Learn more</div>
      <div class="wht_sha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta.svg" width="30px" height="30px"
          alt="..."></div>
      <div class="redsha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta-white.svg" width="30px" height="30px"
          alt="..."></div>
    </div>
  </button>
  <img src="assets/imagenesWeb/Banners/banner_1.png" width="100%" alt="">
</div>
