<div class="di_ov">
    <div class="ban_abt">
        <div class="abt_txt">
            <h3>Insights</h3>
        </div>
        <div class="img_abt"><img src="assets/imagenesWeb/About/a_header.png" width="100%" alt="..."></div>
    </div>
</div>
<div class="o_exp">
    <br><br>
    <div class="card_fle">
        <div class="card" >
            <img src="assets/6522089_orig.jpg" class="card-img-top" alt="...">
            <div class="card-body">
                <h3>What we offer</h3>
                <h5>Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora autem asperiores sunt tenetur minus corrupti cumque unde dicta,</h5>

            </div>
        </div>
        <div class="card" >
            <img src="assets/6522089_orig.jpg" class="card-img-top" alt="...">
            <div class="card-body">
                <h3>What we offer</h3>
                <h5>Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora autem asperiores sunt tenetur minus corrupti cumque unde dicta,</h5>
            </div>
        </div>
        <div class="card">
            <img src="assets/6522089_orig.jpg" class="card-img-top" alt="...">
            <div class="card-body">
                <h3>What we offer</h3>
                <h5>Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora autem asperiores sunt tenetur minus corrupti cumque unde dicta,</h5>
            </div>
        </div>
    </div>
    <br><br>
    <br>
</div>
<div class="co_sert">
    <br>
    <div class="sert_sec">
        <div class="txt_sert">
            <h2>Service 3</h2>
            <h5>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam</h5>
            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna.</p>
            <h6>Lorem ipsum dolor sit amet, consetetur</h6>
            <button type="button" class="btn_bred" routerLink="/servicios">
                <div class="btn_fle"> <div class="txt_fle">Learn more</div>  <div class="wht_sha"><img  src="assets/iconosWeb/WLNIconos/flecha-correcta-white.svg" width="30px" height="30px" alt="..."></div> <div class="redsha"><img  src="assets/iconosWeb/WLNIconos/flecha-correcta.svg" width="30px" height="30px" alt="..."></div> </div>                            
            </button>
        </div>
        <div class="fram_sert">
            <div></div>
        </div>

    </div>
    <br><br><br>
</div>

<div class="ban_lov">
    <button type="button" class="btn_bred" routerLink="/servicios">
        <div class="btn_fle"> <div class="txt_fle">Contact us</div><div class="wht_sha"><img  src="assets/iconosWeb/WLNIconos/flecha-correcta.svg" width="30px" height="30px" alt="..."></div> <div class="redsha"><img  src="assets/iconosWeb/WLNIconos/flecha-correcta-white.svg" width="30px" height="30px" alt="..."></div> </div>                            
    </button>
    <img src="assets/imagenesWeb/Banners/banner_6.png" width="100%" alt="...">
</div>