<app-menu-wrapper></app-menu-wrapper>
<div class="ban_abt">
    <div class="abt_txt">
        <h3>White-Label <br> Photography <br> and video <br> editing</h3>
        <p>Nearshore audiovisual editing team</p>
        <button type="button" class="btn_bred" routerLink="/contact">
            <div class="btn_fle">
                <div class="txt_fle">Book a call</div>
                <div class="wht_sha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta-white.svg" width="30px"
                        height="30px" alt="..."></div>
                <div class="redsha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta.svg" width="30px" height="30px"
                        alt="..."></div>
            </div>
        </button>
    </div>
    <div class="img_abt"><img src="assets/imagenesWeb/Service/Services - Pho video editing/S_edit header.png"
            width="100%" alt="..."></div>
</div>
<div class="hands_pic">
    <div class="ul_hand_pic">
        <h3 class="page-title">
            White-Label Nearshore <br> photo and video editing
        </h3>
        <p class="display-text">
            We help your Company to build a backoffice nearshore team <br> Scale your costs and revenue with us. Send us
            the job <br> requirements and we would
            seek and hire the right talent for your <br> company. We offer full time, part time and on demand services.
            <br>
            <span>Our nearshore talent usually have:</span>
        </p>
        <ul>
            <li>
                <span>Technical English skills</span>
            </li>
            <li>
                <span>Engineer or technical degree</span>
            </li>
            <li><span>High sense of responsibility</span>
            </li>
            <li>
                <span>Tech savy and computer skills</span>
            </li>
            <li>
                <span>Full access to internet</span>
            </li>
        </ul>

        <button type="button" class="btn_bred" routerLink="/contact">
            <div class="btn_fle">
                <div class="txt_fle">Request a quote</div>
                <div class="wht_sha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta-white.svg" width="30px"
                        height="30px" alt="..."></div>
                <div class="redsha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta.svg" width="30px" height="30px"
                        alt="..."></div>
            </div>
        </button>
    </div>
    <div class="fram_sefo">
        <div></div>
    </div>
    <div class="pic-gosht">
        <img src="assets/imagenesWeb/Service/Services - Development/s_skill.png" width="100%" alt="...">
    </div>


</div>
<div class="o_exp">
    <br>

    <div id="carouseldesk" class="carousel slide carousel-dark " data-bs-ride="carousel">
        <div class="carousel-inner">
            <div class="carousel-item active">
                <div class="card_fle">
                    <div class="card">
                        <div class="gro_wh">
                            <img src="assets/iconosWeb/WLNIconos/it staff augement - wln.svg" class="card-img-top"
                                alt="...">
                        </div>
                        <div class="card-body">
                            <h4>Photography editing</h4>
                        </div>
                    </div>
                    <div class="card">
                        <div class="gro_wh">
                            <img src="assets/iconosWeb/WLNIconos/database - wln.svg" class="card-img-top" alt="...">
                        </div>
                        <div class="card-body">
                            <h4>Motion graphics</h4>
                        </div>
                    </div>
                    <div class="card">
                        <div class="gro_wh">
                            <img src="assets/iconosWeb/WLNIconos/front back end - wln.svg" alt="..." />
                        </div>
                        <div class="card-body">
                            <h4>Post production editing</h4>
                        </div>
                    </div>
                    <div class="card">
                        <div class="gro_wh">
                            <img src="assets/iconosWeb/WLNIconos/it technicians - wln.svg" alt="..." />
                        </div>
                        <div class="card-body">
                            <h4>basic and pro video animation</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="carouselmob" class="carousel slide carousel-dark" data-bs-ride="carousel">
        <div class="carousel-inner">
            <div class="carousel-item active">
                <div class="card_fle">
                    <div class="card">
                        <div class="gro_wh">
                            <img src="assets/iconosWeb/WLNIconos/it staff augement - wln.svg" class="card-img-top"
                                alt="...">
                        </div>
                        <div class="card-body">
                            <h4>Photography editing</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-item">
                <div class="card_fle">
                    <div class="card">
                        <div class="gro_wh">
                            <img src="assets/iconosWeb/WLNIconos/database - wln.svg" class="card-img-top" alt="...">
                        </div>
                        <div class="card-body">
                            <h4>Motion graphics</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-item">
                <div class="card_fle">
                    <div class="card">
                        <div class="gro_wh">
                            <img src="assets/iconosWeb/WLNIconos/front back end - wln.svg" alt="..." />
                        </div>
                        <div class="card-body">
                            <h4>Post production editing</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-item">
                <div class="card_fle">
                    <div class="card">
                        <div class="gro_wh">
                            <img src="assets/iconosWeb/WLNIconos/it technicians - wln.svg" alt="..." />
                        </div>
                        <div class="card-body">
                            <h4>basic and pro video animation</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselmob" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselmob" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
    </div>
    <br>

</div>
<div class="team_txt">
    <div class="txt_team_lef">
        <h4>
            Why build <br> a nearshore
        </h4>
        <h3 class="page-title">
            video <br> or photo <br> editing team <br> <span>?</span>
        </h3>
    </div>
    <div class="ul_team">
        <p class="display-text">
            It has been proven that going nearshore provides you with great benefits such as scaling your costs and
            scaling your revenue, not to mention having a long-term nearshore partner. You’ll get the same quality that
            an onshore team can provide at an affordable
            cost.
            <br><br>
            <span>Our value proposition with your company is:</span>

        </p>
        <ul>
            <li>
                <span>Scale your offering</span>
            </li>
            <li>
                <span>Scale your costs</span>
            </li>
            <li><span>Scale your revenue</span>
            </li>
            <li>
                <span>Be you long-term nearshore partner</span>
            </li>
        </ul>
        <button type="button" class="btn_bred" routerLink="/contact">
            <div class="btn_fle">
                <div class="txt_fle">Book a Call</div>
                <div class="wht_sha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta-white.svg" width="30px"
                        height="30px" alt="..."></div>
                <div class="redsha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta.svg" width="30px" height="30px"
                        alt="..."></div>
            </div>
        </button>
    </div>
    <div class="pic_gosht">
        <img src="assets/imagenesWeb/Home/h_why.png" width="100%" alt="...">
    </div>


</div>
<div class="ban_lov">
    <button type="button" class="btn_bred" routerLink="/contact">
        <div class="btn_fle">
            <div class="txt_fle">Contact us</div>
            <div class="wht_sha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta.svg" width="30px" height="30px"
                    alt="..."></div>
            <div class="redsha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta-white.svg" width="30px"
                    height="30px" alt="..."></div>
        </div>
    </button>
    <img src="assets/imagenesWeb/Banners/banner_6.png" width="100%" alt="...">
</div>

<h1 class= "wdn-text-hidden"> White Label Nearshore Photography and video editing</h1>