<app-menu-wrapper>
</app-menu-wrapper>
<div class="abt">
    <div class="abt_txt">
        <h3> Explore <br> <span>our services</span></h3>
    </div>
    <div class="img_abt"><img src="assets/imagenesWeb/Service/s_header.png" width="120%" alt="..."></div>
</div>



<div class="col_ba">
    <br>
    <div class="hear_ba">
        <div class="txt_her">
            <h5>White-Label <br> Social Media <br> Management</h5>
            <h2>Operative and strategical tasks</h2>
            <p class="d-none">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna</p>
            <h6 class="d-none">Lorem ipsum dolor sit amet consetetur</h6>
            <button type="button" class="btn_bred" routerLink="/socialMedia">
                <div class="btn_fle"> <div class="txt_fle">Learn more</div><div class="wht_sha"><img  src="assets/iconosWeb/WLNIconos/flecha-correcta-white.svg" width="30px" height="30px" alt="..."></div> <div class="redsha"><img  src="assets/iconosWeb/WLNIconos/flecha-correcta.svg" width="30px" height="30px" alt="..."></div> </div>                            
            </button>
        </div>
        <div class="pic_her"><img src="assets/imagenesWeb/Service/s_management.png" width="57%" alt="..."></div>
    </div>
    <br><br><br>
</div>
<h1 class= "wdn-text-hidden"> White Label Nearshore Solutions</h1>

<div class="comp_north">
    <div class="pic_comp"><img src="assets/imagenesWeb/Service/s_02.png" width="60%" alt="..."></div>
    <div class="txt_comp">
        <h2>White-Label <br> Development</h2>
        <h5>Full stack Engineers or basic/mid-level IT technicians</h5>
        <p class="d-none">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna</p>
        <h6 class="d-none">Lorem ipsum dolor sit amet consetetur</h6>
        <button type="button" class="btn_bred" routerLink="/development">
            <div class="btn_fle"> <div class="txt_fle">Learn more</div>  <div class="wht_sha"><img  src="assets/iconosWeb/WLNIconos/flecha-correcta-white.svg" width="30px" height="30px" alt="..."></div> <div class="redsha"><img  src="assets/iconosWeb/WLNIconos/flecha-correcta.svg" width="30px" height="30px" alt="..."></div> </div>                            
        </button>
    </div>
</div>
<div class="co_sert d-none">
    <br>
    <div class="sert_sec">
        <div class="txt_sert">
            <h2>Service <span>3</span></h2>
            <h5>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam</h5>
            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna.</p>
            <h6>Lorem ipsum dolor sit amet, consetetur</h6>
            <button type="button" class="btn_bred" routerLink="/development">
                <div class="btn_fle"> <div class="txt_fle">Learn more</div>  <div class="wht_sha"><img  src="assets/iconosWeb/WLNIconos/flecha-correcta-white.svg" width="30px" height="30px" alt="..."></div> <div class="redsha"><img  src="assets/iconosWeb/WLNIconos/flecha-correcta.svg" width="30px" height="30px" alt="..."></div> </div>                            
            </button>
        </div>
        <div class="fram_sert">
            <div></div>
        </div>

    </div>
    <br><br><br>
</div>
<div class="co_sefo d-none">
    <br>
    <div class="sefo_sec">
        <div class="fram_sefo">
            <div></div>
        </div>
        <div class="txt_sefo">
            <h2>Service <span>4</span> </h2>
            <h5>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam</h5>
            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna.</p>
            <h6>Lorem ipsum dolor sit amet, consetetur</h6>
            <button type="button" class="btn_bred" routerLink="/servicios">
                <div class="btn_fle"> <div class="txt_fle">Learn more</div>  <div class="wht_sha"><img  src="assets/iconosWeb/WLNIconos/flecha-correcta-white.svg" width="30px" height="30px" alt="..."></div> <div class="redsha"><img  src="assets/iconosWeb/WLNIconos/flecha-correcta.svg" width="30px" height="30px" alt="..."></div> </div>                            
            </button>
        </div>
    </div>
    <br><br><br>
</div>
<div class="co_sefive d-none">
    <br>
    <div class="sefive_sec">
        <div class="txt_sefive">
            <h2>Service <span>5</span></h2>
            <h5>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam</h5>
            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna.</p>
            <h6>Lorem ipsum dolor sit amet, consetetur</h6>
            <button type="button" class="btn_bred" routerLink="/servicios">
                <div class="btn_fle"> <div class="txt_fle">Learn more</div>  <div class="wht_sha"><img  src="assets/iconosWeb/WLNIconos/flecha-correcta-white.svg" width="30px" height="30px" alt="..."></div> <div class="redsha"><img  src="assets/iconosWeb/WLNIconos/flecha-correcta.svg" width="30px" height="30px" alt="..."></div> </div>                            
            </button>
        </div>
        <div class="fram_sefive">
            <div></div>
        </div>
    </div>
    <br><br><br>
</div>
<div class="co_six d-none">
    <br>
    <div class="six_sec">
        <div class="fram_six">
            <div></div>
        </div>
        <div class="txt_six">
            <h2>Service <span>6</span></h2>
            <h5>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam</h5>
            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna.</p>
            <h6>Lorem ipsum dolor sit amet, consetetur</h6>
            <button type="button" class="btn_bred" routerLink="/servicios">
                <div class="btn_fle"> <div class="txt_fle">Learn more</div>  <div class="wht_sha"><img  src="assets/iconosWeb/WLNIconos/flecha-correcta-white.svg" width="30px" height="30px" alt="..."></div> <div class="redsha"><img  src="assets/iconosWeb/WLNIconos/flecha-correcta.svg" width="30px" height="30px" alt="..."></div> </div>                            
            </button>
        </div>
    </div>
    <br><br><br>
</div>
<div class="ban_lov">
    <button type="button" class="btn_bred" routerLink="/contact">
        <div class="btn_fle"> <div class="txt_fle">Contact us</div><div class="wht_sha"><img  src="assets/iconosWeb/WLNIconos/flecha-correcta.svg" width="30px" height="30px" alt="..."></div> <div class="redsha"><img  src="assets/iconosWeb/WLNIconos/flecha-correcta-white.svg" width="30px" height="30px" alt="..."></div> </div>                            
    </button>
    <img src="assets/imagenesWeb/Banners/banner_4.png" width="100%" alt="...">
</div>