<header class="head fixed-top">
    <div class="container-fluid ">
        <div class="logo-nav-container">
            <a routerLink="/home" class="logo"><img src="assets/iconosWeb/WLNIconos/logo negro - wln.svg" alt="..." width="150px" height="95px"></a>
        </div>
        <nav class="header">
            <input type="checkbox" id="check">
            <label for="check" class="checkbtn" (click)="toggleState()">
                 <i><img  id="menu-bars" src="assets/menus.svg" width="40px" height="40px" alt="..."></i>
            </label>
            <ul class="rojos cerrar" [ngClass]="{ 'open': isCollapse }">
                <li><a class="not-act" (click)="toggleState()" routerLink="/home" routerLinkActive="win-ac">Home</a></li>
                <li><a class="not-act" (click)="toggleState()" routerLink="/about-whitelabel-nearshore" routerLinkActive="win-ac">About</a></li>
                <li><a class="not-act" (click)="toggleState()" routerLink="/solutions" routerLinkActive="win-ac">Solutions</a></li>
                <li><a class="not-act d-none" (click)="toggleState()" routerLink="/insights" routerLinkActive="win-ac">Insights</a></li>
                <li><a class="not-act" (click)="toggleState()" routerLink="/contact" routerLinkActive="win-ac">contact</a></li>
                <li><button type="button" (click)="toggleState()" routerLink="/contact">Start a project</button></li>
            </ul>
        </nav>
    </div>
</header>
<br />
<br /><br /><br>