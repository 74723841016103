<app-menu-wrapper></app-menu-wrapper>
<div class="ban_abt">
  <div class="abt_txt">
    <h3>White-Label <br> Graphic Design</h3>
    <p>Scale your costs and revenue with <br> a nearshore graphic design team</p>
    <button type="button" class="btn_bred" routerLink="/contact">
      <div class="btn_fle">
        <div class="txt_fle">Book a call</div>
        <div class="wht_sha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta-white.svg" width="30px" height="30px"
            alt="..."></div>
        <div class="redsha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta.svg" width="30px" height="30px"
            alt="..."></div>
      </div>
    </button>
  </div>
  <div class="img_abt"><img src="assets/imagenesWeb/Service/Services - Graphic design/s_graphic header.png" width="100%"
      alt="..."></div>
</div>
<div class="hands_pic">
  <div class="ul_hand_pic">
    <h3 class="page-title">
      White-Label <br> Graphic Design
    </h3>
    <p class="display-text">
      We help your Marketing Department to build a nearshore graphic <br> design team Scale your costs and revenue with
      us. Send us <br> the job requirements and we would seek and hire the right talent for <br> your company. Full
      time, part-time and freelance services
      <br>
      <span>Our nearshore talent usually have:</span>

    </p>
    <ul>
      <li>
        <span>Technical English skills</span>
      </li>
      <li>
        <span>Engineer or technical degree</span>
      </li>
      <li><span>High sense of responsibility</span>
      </li>
      <li>
        <span>Tech savy and computer skills</span>
      </li>
      <li>
        <span>Full access to internet</span>
      </li>
    </ul>

    <button type="button" class="btn_bred" routerLink="/contact">
      <div class="btn_fle">
        <div class="txt_fle">Request a quote</div>
        <div class="wht_sha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta-white.svg" width="30px" height="30px"
            alt="..."></div>
        <div class="redsha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta.svg" width="30px" height="30px"
            alt="..."></div>
      </div>
    </button>
  </div>
  <div class="fram_sefo">
    <div></div>
  </div>
  <div class="pic-gosht">
    <img src="assets/imagenesWeb/Service/Services - Development/s_skill.png" width="100%" alt="...">
  </div>


</div>
<div class="co_sefo d-none">
  <br>
  <div class="sefo_sec">
    <div class="fram_sefo">
      <div></div>
    </div>
    <div class="txt_sefo">
      <h2>Service <span>1</span></h2>
      <h5>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam</h5>
      <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
        dolore magna.</p>
      <h6>Lorem ipsum dolor sit amet, consetetur</h6>
      <button type="button" class="btn_bred" routerLink="/solutions">
        <div class="btn_fle">
          <div class="txt_fle">Learn more</div>
          <div class="wht_sha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta-white.svg" width="30px"
              height="30px" alt="..."></div>
          <div class="redsha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta.svg" width="30px" height="30px"
              alt="..."></div>
        </div>
      </button>
    </div>
  </div>
  <br><br><br>
</div>
<div class="o_exp">
  <br>
  <h2>White-Label solutions <br> we offer to your company</h2>

  <div id="carouseldesk" class="carousel slide carousel-dark " data-bs-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <div class="card_fle">
          <div class="card">
            <div class="gro_wh">
              <img src="assets/iconosWeb/WLNIconos/it staff augement - wln.svg" class="card-img-top" alt="...">
            </div>
            <div class="card-body">
              <h4>Social Media creatives</h4>
            </div>
          </div>
          <div class="card">
            <div class="gro_wh">
              <img src="assets/iconosWeb/WLNIconos/database - wln.svg" class="card-img-top" alt="...">
            </div>
            <div class="card-body">
              <h4>ATL Media Press, advertising</h4>
            </div>
          </div>
          <div class="card">
            <div class="gro_wh">
              <img src="assets/iconosWeb/WLNIconos/front back end - wln.svg" alt="..." />
            </div>
            <div class="card-body">
              <h4>Magazine online and offline</h4>
            </div>
          </div>
          <div class="card">
            <div class="gro_wh">
              <img src="assets/iconosWeb/WLNIconos/it technicians - wln.svg" alt="..." />
            </div>
            <div class="card-body">
              <h4>Flyers and email design</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="card_fle">
          <div class="card">
            <div class="gro_wh">
              <img src="assets/iconosWeb/WLNIconos/front back end devs - wln.svg" class="card-img-top" alt="...">
            </div>
            <div class="card-body">
              <h4>Branded marketing materials</h4>
            </div>
          </div>
          <div class="card">
            <div class="gro_wh">
              <img src="assets/iconosWeb/WLNIconos/infrastructure engineers - wln.svg" class="card-img-top" alt="...">
            </div>
            <div class="card-body">
              <h4>Sales materials Broshures, ppts</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouseldesk" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouseldesk" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>

  <div id="carouselmob" class="carousel slide carousel-dark" data-bs-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <div class="card_fle">
          <div class="card">
            <div class="gro_wh">
              <img src="assets/iconosWeb/WLNIconos/it staff augement - wln.svg" class="card-img-top" alt="...">
            </div>
            <div class="card-body">
              <h4>IT Staff augmentation</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="card_fle">
          <div class="card">
            <div class="gro_wh">
              <img src="assets/iconosWeb/WLNIconos/database - wln.svg" class="card-img-top" alt="...">
            </div>
            <div class="card-body">
              <h4>Database Administrator DBA</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="card_fle">
          <div class="card">
            <div class="gro_wh">
              <img src="assets/iconosWeb/WLNIconos/front back end - wln.svg" alt="..." />
            </div>
            <div class="card-body">
              <h4>Webdesign and website development</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="card_fle">
          <div class="card">
            <div class="gro_wh">
              <img src="assets/iconosWeb/WLNIconos/it technicians - wln.svg" alt="..." />
            </div>
            <div class="card-body">
              <h4>IT Technicians - Helpdesk Support</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="card_fle">
          <div class="card">
            <div class="gro_wh">
              <img src="assets/iconosWeb/WLNIconos/front back end devs - wln.svg" class="card-img-top" alt="...">
            </div>
            <div class="card-body">
              <h4>Front-End/Back-End Devs</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="card_fle">
          <div class="card">
            <div class="gro_wh">
              <img src="assets/iconosWeb/WLNIconos/infrastructure engineers - wln.svg" class="card-img-top" alt="...">
            </div>
            <div class="card-body">
              <h4>Infrastructure Engineers</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="card_fle">
          <div class="card">
            <div class="gro_wh">
              <img src="assets/iconosWeb/WLNIconos/cloud network engineers - wln.svg" alt="..." />
            </div>
            <div class="card-body">
              <h4>Cloud/Network Engineers</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="card_fle">
          <div class="card">
            <div class="gro_wh">
              <img src="assets/iconosWeb/WLNIconos/web apps dev - wln.svg" class="card-img-top" alt="...">
            </div>
            <div class="card-body">
              <h4>Web/APPS developers</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="card_fle">
          <div class="card">
            <div class="gro_wh">
              <img src="assets/iconosWeb/WLNIconos/back office - wln.svg" alt="..." />
            </div>
            <div class="card-body">
              <h4>Back Office</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselmob" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselmob" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>


  <br><br>
  <h2>Stack of experience</h2>
  <br><br>
  <div class="icons_fle">
    <div>
      <img src="assets/iconosWeb/desing_icons/Artboard 6.svg" width="40px" height="40px" alt="...">
      <h3>Adobe Cloud <br>Suite</h3>
    </div>
    <div>
      <img src="assets/iconosWeb/desing_icons/Artboard 7.svg" width="40px" height="40px" alt="...">
      <h3>Photoshop</h3>
    </div>
    <div>
      <img src="assets/iconosWeb/desing_icons/Artboard 5.svg" width="40px" height="40px" alt="...">
      <h3>Illustrator</h3>
    </div>
  </div>
  <div class="icons_fle">
    <div>
      <img src="assets/iconosWeb/desing_icons/Artboard 4.svg" width="40px" height="40px" alt="...">
      <h3>Indesign</h3>
    </div>
    <div>
      <img src="assets/iconosWeb/desing_icons/Artboard 3.svg" width="40px" height="40px" alt="...">
      <h3>Adobe XD</h3>
    </div>
  </div>
  <br><br><br><br>

</div>
<div class="icon_our">
  <br><br>
  <h1><span>Our </span> White-label <br> process</h1>
  <div class="icon_hom">
    <div class="txt_ima_ico">
      <img src="assets/iconosWeb/WLNIconos/the request - wln.svg" width="95px" height="95px" alt="...">
      <br>
      <h3> <span>We receive</span> the request <br> from your company</h3>
    </div>
    <div class="txt_ima_ico">
      <img src="assets/iconosWeb/WLNIconos/filter talent - wln.svg" width="95px" height="95px" alt="...">
      <br>
      <h3><span>We recruit, interview <br> and</span> filter talent for you</h3>
    </div>
    <div class="txt_ima_ico">
      <img src="assets/iconosWeb/WLNIconos/select talent - wln.svg" width="95px" height="95px" alt="...">
      <h3><span>You select the <br> nearshore talent</span> that <br> fills better the opening</h3>

    </div>
    <div class="txt_ima_ico">
      <img src="assets/iconosWeb/WLNIconos/we hire - wln.svg" width="95px" height="95px" alt="...">
      <h3><span>We hire, train and <br> manage</span> the nearshore <br> talent based on <br> Guatemala City</h3>

    </div>
  </div>
</div>
<div class="col_ca_of d-none">
  <br><br>
  <div class="tx_ca">
    <div class="tx_wht">
      <h2>What <br> clients <br> <span>say <br> about us</span> </h2>
    </div>
    <div class="car_cl">
      <div class="gallery js-flickity" data-flickity-options='{ "freeScroll": true, "wrapAround": true }'>
        <div class="gallery-cell">
          <div class="card" style="width: 243px; height: 300px;">
            <img src="assets/6522089_orig.jpg" class="card-img-top" alt="...">
            <div class="card-body">
              <h4>“WLN is the partner whom you can trust”</h4>
              <hr>
              <h3>Orlando</h3>
              <h5>Agency</h5>
              <p>USA</p>

            </div>
          </div>

        </div>
        <div class="gallery-cell">
          <div class="card" style="width: 243px; height: 300px;">
            <img src="assets/6522089_orig.jpg" class="card-img-top" alt="...">
            <div class="card-body">
              <h4>“This guys knows what they do”</h4>
              <hr>
              <h3>Jose Rodriguez</h3>
              <h5>Founder</h5>
              <p>Paralelo Studio</p>
            </div>
          </div>
        </div>
        <div class="gallery-cell">
          <div class="card" style="width: 243px; height: 300px;">
            <img src="assets/6522089_orig.jpg" class="card-img-top" alt="...">
            <div class="card-body">
              <h4>“WLN delivers with accountability”</h4>
              <hr class="h_card">
              <h3>Vinnizio RIzo</h3>
              <h5>Client Director</h5>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <br><br>
</div>
<div class="ban_lov">
  <button type="button" class="btn_bred" routerLink="/contact">
    <div class="btn_fle">
      <div class="txt_fle">Contact us</div>
      <div class="wht_sha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta.svg" width="30px" height="30px"
          alt="..."></div>
      <div class="redsha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta-white.svg" width="30px" height="30px"
          alt="..."></div>
    </div>
  </button>
  <img src="assets/imagenesWeb/Banners/banner_6.png" width="100%" alt="...">
</div>
<div class="team_txt">
  <div class="txt_team_lef">
    <h4>
      Why build <br> a nearshore
    </h4>
    <h3 class="page-title">
      graphic <br> design team <br> <span>?</span>
    </h3>
  </div>
  <div class="ul_team">
    <p class="display-text">
      It has been proven that going nearshore provides you with great benefits such as scaling your costs and scaling
      your revenue, not to mention having a long-term nearshore partner. You’ll get the same quality that an onshore
      team can provide at an affordable
      cost.
      <br><br>
      <span>Our value proposition with your company is:</span>

    </p>
    <ul>
      <li>
        <span>Scale your offering</span>
      </li>
      <li>
        <span>Scale your costs</span>
      </li>
      <li><span>Scale your revenue</span>
      </li>
      <li>
        <span>Be you long-term nearshore partner</span>
      </li>
    </ul>
    <button type="button" class="btn_bred" routerLink="/contact">
      <div class="btn_fle">
        <div class="txt_fle">Book a Call</div>
        <div class="wht_sha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta-white.svg" width="30px" height="30px"
            alt="..."></div>
        <div class="redsha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta.svg" width="30px" height="30px"
            alt="..."></div>
      </div>
    </button>
  </div>
  <div class="pic_gosht">
    <img src="assets/imagenesWeb/Home/h_why.png" width="100%" alt="...">
  </div>


</div>
<div class="ban_glo">
  <button type="button" class="btn_bred" routerLink="/contact">
    <div class="btn_fle">
      <div class="txt_fle">Contact us</div>
      <div class="wht_sha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta.svg" width="30px" height="30px"
          alt="..."></div>
      <div class="redsha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta-white.svg" width="30px" height="30px"
          alt="..."></div>
    </div>
  </button>
  <img src="assets/imagenesWeb/Banners/banner_4.png" width="100%" alt="...">
</div>

<h1 class="wdn-text-hidden"> White Label Nearshore Graphic Design</h1>
