<div class="home">
    <div class="ban1">
        <div id="ban_caro" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active one-pic">
                    <img src="assets/imagenesWeb/Home/h_slide1.png" width="100%" alt="orange tree" />
                    <div class="carousel-text d-lg-block">
                        <h3 class="page-title ">
                            IT HAS BEEN PROVEN
                        </h3>
                        <p class="display-text txt_mi">
                            That going nearshore provides you with great benefits Such as scaling your costs and scaling
                            your revenue.
                        </p>
                        <button type="button" class="btn_bred" routerLink="/solutions">
                            <div class="btn_fle">
                                <div class="txt_fle">Explore our white label solutions</div>
                                <div class="wht_sha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta-white.svg"
                                        width="30px" height="30px" alt="..."></div>
                                <div class="redsha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta.svg"
                                        width="30px" height="30px" alt="..."></div>
                            </div>
                        </button>
                    </div>
                </div>
                <div class="carousel-item">
                    <img src="assets/imagenesWeb/Home/h_slide2.png" width="100%" alt="submerged" />
                    <div class="carousel-text-half d-lg-block">
                        <h3 class="page-title ">
                            WHITE-LABEL NEARSHORE
                        </h3>
                        <p class="display-text">
                            A DIGITAL EXPERIENCED OUTSOURING COMPANY
                        </p>
                        <button type="button" class="btn_bred" routerLink="/solutions">
                            <div class="btn_fle">
                                <div class="txt_fle">Explore our white label solutions</div>
                                <div class="wht_sha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta-white.svg"
                                        width="30px" height="30px" alt="..."></div>
                                <div class="redsha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta.svg"
                                        width="30px" height="30px" alt="..."></div>
                            </div>

                        </button>
                    </div>
                </div>
                <div class="carousel-item one-pic">
                    <img src="assets/imagenesWeb/Home/h_slide 3.png" width="100%" alt="look-out" />
                    <div class="carousel-text d-lg-block">
                        <h3 class="page-title ">
                            OUTSOURCING SOLUTIONS
                        </h3>
                        <p class="display-text">
                            For US agencies and companies
                        </p>
                        <button type="button" class="btn_bred" routerLink="/solutions">
                            <div class="btn_fle">
                                <div class="txt_fle">Solutions for US agencies</div>
                                <div class="wht_sha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta-white.svg"
                                        width="30px" height="30px" alt="..."></div>
                                <div class="redsha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta.svg"
                                        width="30px" height="30px" alt="..."></div>
                            </div>

                        </button>
                    </div>
                </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#ban_caro" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#ban_caro" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>
    </div>
    <div class="text1">
        <h6>Welcome to the official website of</h6>
        <h1>WHITE-LABEL <br> NEARSHORE</h1>
        <h5>Your nearshore partner for outsourcing services tailored to your needs</h5>
        <h3>You send the request, <br> we do the rest</h3>
    </div>
    <div class="icon_hom">

        <div class="txt_ima_ico">
            <img src="assets/iconosWeb/WLNIconos/hiring - wln.svg" width="75px" height="75px" alt="...">
            <h3>Hiring</h3>
            <div class="bor_frame">
                <h4>Hiring</h4>
                <p>Hire the right talent according the job profile you need</p>
            </div>

        </div>

        <div class="txt_ima_ico">
            <img src="assets/iconosWeb/WLNIconos/training - wln.svg" width="75px" height="75px" alt="...">
            <h3>Training</h3>
            <div class="bor_frame">
                <h4>Training</h4>
                <p>Train your nearshore team with your processes and requirements</p>
            </div>
        </div>
        <div class="txt_ima_ico">
            <img src="assets/iconosWeb/WLNIconos/leadership - wln.svg" width="75px" height="75px" alt="...">
            <h3>Leadership <br> Management</h3>
            <div class="bor_frame">
                <h4>Leadership Management</h4>
                <p>Manage your nearshore team</p>
            </div>
        </div>
        <div class="txt_ima_ico">
            <img src="assets/iconosWeb/WLNIconos/leverage - wln.svg" width="75px" height="75px" alt="...">
            <h3>Leverage</h3>
            <div class="bor_frame">
                <h4>Leverage</h4>
                <p>Leverage their performance and results side by side</p>
            </div>
        </div>
    </div>
    <br><br>
    <h6 class="our_from">*Our services are delivered remotely from Guatemala City, CA</h6>
    <div class="hands_pic">
        <div class="txt_hand_pic">
            <h3 class="page-title">
                Why <br> going <br> nearshore <br> <span>?</span>
            </h3>
        </div>
        <div class="ul_hand_pic">
            <p class="display-text">
                It has been proven that going nearshore provides you with great benefits such as scaling your costs and
                scaling your revenue, not to mention having a long-term nearshore partner. You’ll get the same quality
                that an onshore team can provide at an affordable
                cost.
                <br><br>
                <span>Our value proposition with your company is:</span>
            </p>
            <ul>
                <li>
                    <span>Scale your offering</span>
                </li>
                <li>
                    <span>Scale your costs</span>
                </li>
                <li><span>Scale your revenue</span>
                </li>
                <li>
                    <span>Be you long-term nearshore partner</span>
                </li>
            </ul>
            <button type="button" class="btn_bred" routerLink="/solutions">
                <div class="btn_fle">
                    <div class="txt_fle">Learn more</div>
                    <div class="wht_sha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta-white.svg" alt="...">
                    </div>
                    <div class="redsha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta.svg" width="30px"
                            height="30px" alt="..."></div>
                </div>
            </button>
        </div>
        <div class="carousel-text  d-none">
            <div>
                <h3 class="page-title text-white">
                    Why <br> going <br> nearshore <br> <span>?</span>
                </h3>
            </div>
            <div>
                <p class="display-text">
                    Tenemos el mejor servicio y atención al cliente,
                    <br> en nuestras diferentes especialidades, con la mejor <br> infraestructura y los mejores médicos
                </p>
                <br />
                <button type="button" class="btn btn-primary btn-slide" routerLink="/servicios">
                    Consultar Servicios
                </button>
            </div>

        </div>

        <img src="assets/imagenesWeb/Home/h_why.png" width="100%" alt="...">

    </div>
    <br>
    <div class="map_north">
        <div class="pic_map"><img src="assets/imagenesWeb/Home/h_mapa.png" width="70%" alt="..."></div>
        <div class="txt_map">
            <h5>Our strategic location</h5>
            <h2>Nearshore <br> location</h2>
            <p>We are located in Guatemala City, Central America. 2-3 hrs flight distance from US. Guatemala is a city
                with qualified talent, affordable for your needs. We adhere to your time-zones and work with your
                schedules. The talent we hire for your
                company have Technical English skills.</p>
            <h5>Contact us today!</h5>
            <p>We would love to be your long-term nearshore partner.</p>
            <button type="button" class="btn_bred" routerLink="/about-whitelabel-nearshore">
                <div class="btn_fle">
                    <div class="txt_fle">Learn more</div>
                    <div class="wht_sha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta-white.svg" width="30px"
                            height="30px" alt="..."></div>
                    <div class="redsha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta.svg" width="30px"
                            height="30px" alt="..."></div>
                </div>
            </button>
        </div>
    </div>
    <br><br>
    <div class="gray_icon ">
        <br>
        <h3 class="txt_fin">Find the perfect service <br> according to your needs</h3>
        <p>We are the nearshore factory for digital solutions</p>
        <div class="icons_fle on-ro">
            <div routerLink="/socialMedia">
                <img src="assets/iconosWeb/WLNIconos/social media - wln.svg" width="50px" height="50px" alt="...">
                <h3>Social Media</h3>
            </div>
            <div routerLink="/socialMedia">
                <img src="assets/iconosWeb/WLNIconos/comments moderator - wln.svg" width="50px" height="50px" alt="...">
                <h3>Comments Moderator</h3>
            </div>
            <div routerLink="/website">
                <img src="assets/iconosWeb/WLNIconos/front back end - wln.svg" width="50px" height="50px" alt="...">
                <h3>Front end/ Back end</h3>
            </div>
            <div routerLink="/development">
                <img src="assets/iconosWeb/WLNIconos/development - wln.svg" width="50px" height="50px" alt="...">
                <h3>Development</h3>
            </div>
        </div>
        <div class="icons_fle tw-ro">
            <div routerLink="/backOffice">
                <img src="assets/iconosWeb/WLNIconos/back office - wln.svg" width="50px" height="50px" alt="...">
                <h3>Back office</h3>
            </div>
            <div routerLink="/backOffice">
                <img src="assets/iconosWeb/WLNIconos/staff augmentation - wln.svg" width="50px" height="50px" alt="...">
                <h3>Staff <br> Augmentation</h3>
            </div>
            <div routerLink="/development">
                <img src="assets/iconosWeb/WLNIconos/software and apps - wln.svg" width="50px" height="50px" alt="...">
                <h3>Software <br> and Apps</h3>
            </div>
            <div routerLink="/backOffice">
                <img src="assets/iconosWeb/WLNIconos/BPO - wln.svg" width="50px" height="50px" alt="...">
                <h3>BPO</h3>
            </div>
        </div>
        <hr class="lin_our">
        <h6 class="our_from"><span>*</span>Our services are delivered remotely from Guatemala City, CA</h6>
        <br>
    </div>
    <div class="abt">
        <div class="abt_txt">
            <h3>About us</h3>
            <h4>White Label Nearshore is constantly seeking US companies to partner with us</h4>
            <p>We want to be the nearshore digital factory for US companies and agencies, we have qualified talent ready
                to start working for your firm.</p>
            <h5>Our people know collaboration is the essence of success.</h5>
            <button type="button" class="btn_bred" routerLink="/about-whitelabel-nearshore">
                <div class="btn_fle">
                    <div class="txt_fle">Get to know us</div>
                    <div class="wht_sha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta-white.svg" width="30px"
                            height="30px" alt="..."></div>
                    <div class="redsha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta.svg" width="30px"
                            height="30px" alt="..."></div>
                </div>
            </button>
        </div>
        <div class="img_abt"><img src="assets/imagenesWeb/Home/h_know.png" width="100%" height="100%" alt="..."></div>
    </div>
    <div class="banspace">
        <button type="button" class="btn_bred" routerLink="/servicios">
            <div class="btn_fle">
                <div class="txt_fle">Learn more</div>
                <div class="wht_sha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta.svg" width="30px"
                        height="30px" alt="..."></div>
                <div class="redsha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta-white.svg" width="30px"
                        height="30px" alt="..."></div>
            </div>
        </button>
        <img src="assets/imagenesWeb/Banners/banner_1.png" width="100%" alt="">
    </div>
    <div class="lor_sec">
        <div class="fram_lor">
            <div></div>
        </div>
        <div class="txt_lor">
            <h2>Motion Graphics</h2>
            <h5>Discover this trending of the video animation and add this service to your marketing stack.</h5>
            <p>WhiteLabel Nearshore offers your firm staff augmentation solutions for video <br> animation or mographs.
                Our rates are afordable and scalable for your needs. <br>White-Label your video animations with us. We
                have a specialty nearshore <br> team with a high sense of responsibility, customer transparency and
                strict <br> adherence to deadlines while oriented towards results.</p>
            <button type="button" class="btn_bred" routerLink="/photoVideo">
                <div class="btn_fle">
                    <div class="txt_fle">Learn more</div>
                    <div class="wht_sha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta-white.svg" width="30px"
                            height="30px" alt="..."></div>
                    <div class="redsha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta.svg" width="30px"
                            height="30px" alt="..."></div>
                </div>
            </button>
        </div>
    </div>
    <div class="o_exp">
        <br><br>
        <h2>Our Experience</h2>
        <p>Our mission is to deliver remarkable experiences <br> and superior results for our partners</p>
        <div class="card_fle">
            <div class="card">
                <img src="assets/6522089_orig.jpg" class="card-img-top" alt="...">
                <div class="card-body">
                    <h4>"White Label Nearshore, has been an exceptional partner to our agency. Their team is fluent in
                        English and Spanish, very professional, organized and meticulous in their work. We've been very
                        pleased with the level of service their
                        nearshore agency has provided. "</h4>
                    <hr>
                    <h3>Digital Agency in Orlando, FL</h3>
                </div>
            </div>
            <div class="card">
                <img src="assets/6522089_orig.jpg" class="card-img-top" alt="...">
                <div class="card-body">
                    <h4>"I feel comfortable outsourcing all the requests of Mographs I receive from my Clients."</h4>
                    <hr>
                    <h3>Audiovisual Production in Guatemala, City</h3>
                </div>
            </div>
            <div class="card">
                <img src="assets/6522089_orig.jpg" class="card-img-top" alt="...">
                <div class="card-body">
                    <h4>"WLN provides me a hand when I need it. They also helps me to accomplish deadlines. They are a
                        company whom you can trusth"</h4>
                    <hr>
                    <h3>Audiovisual Production in Guatemala, City</h3>
                </div>
            </div>
            <div class="card">
                <img src="assets/6522089_orig.jpg" class="card-img-top" alt="...">
                <div class="card-body">
                    <h4>"I used to work with an offshore dev team in India, but they didn't adhere to my deadlines and
                        requirements. I feel very happy with WLN and our long-term partnership "</h4>
                    <hr class="h_card">
                    <h3>Telemedicine App in Seattle, WA</h3>
                </div>
            </div>

        </div>
        <br><br>
        <br>
        <div id="home-card" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <div class="card">
                        <img src="assets/6522089_orig.jpg" class="card-img-top" alt="...">
                        <div class="card-body">
                            <h4>"White Label Nearshore, has been an exceptional partner to our agency. Their team is
                                fluent in English and Spanish, very professional, organized and meticulous in their
                                work. We've been very pleased with the level of service
                                their nearshore agency has provided. "</h4>
                        </div>
                        <div class="card-footer">
                            <hr>
                            <h3>Digital Agency in Orlando, FL</h3>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="card">
                        <img src="assets/6522089_orig.jpg" class="card-img-top" alt="...">
                        <div class="card-body">
                            <h4>"I feel comfortable outsourcing all the requests of Mographs I receive from my Clients."
                            </h4>
                        </div>
                        <div class="card-footer">
                            <hr>
                            <h3>Audiovisual Production in Guatemala, City</h3>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="card">
                        <img src="assets/6522089_orig.jpg" class="card-img-top" alt="...">
                        <div class="card-body">
                            <h4>"WLN provides me a hand when I need it. They also helps me to accomplish deadlines. They
                                are a company whom you can trusth"</h4>
                        </div>
                        <div class="card-footer">
                            <hr>
                            <h3>Audiovisual Production in Guatemala, City</h3>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="card">
                        <img src="assets/6522089_orig.jpg" class="card-img-top" alt="...">
                        <div class="card-body">
                            <h4>"I used to work with an offshore dev team in India, but they didn't adhere to my
                                deadlines and requirements. I feel very happy with WLN and our long-term partnership "
                            </h4>
                        </div>
                        <div class="card-footer">
                            <hr class="h_card">
                            <h3>Telemedicine App in Seattle, WA</h3>
                        </div>
                    </div>
                </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#home-card" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#home-card" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>
    </div>
    <div class="ban_pho">
        <button type="button" class="btn_bred" routerLink="/contact">
            <div class="btn_fle">
                <div class="txt_fle">Contact us</div>
                <div class="wht_sha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta.svg" width="30px"
                        height="30px" alt="..."></div>
                <div class="redsha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta-white.svg" width="30px"
                        height="30px" alt="..."></div>
            </div>
        </button>
        <img src="assets/imagenesWeb/Banners/banner_2.png" width="100%" alt="...">
    </div>
    <div class="indus">
        <h2>Industries <br> we serve</h2>
        <br><br>
        <div class="indus_icon">
            <div>
                <img src="assets/iconosWeb/WLNIconos/turism - wln.svg" width="50px" height="50px" alt="..">
                <h3>Turism</h3>
            </div>
            <div>
                <img src="assets/iconosWeb/WLNIconos/ad production and film - wln.svg" width="50px" height="50px"
                    alt="..">
                <h3>Advertising, <br> Production and Film</h3>
            </div>
            <div>
                <img src="assets/iconosWeb/WLNIconos/goverment - wln.svg" width="50px" height="50px" alt="..">
                <h3>Government</h3>
            </div>
            <div>
                <img src="assets/iconosWeb/WLNIconos/BPO - wln.svg" width="50px" height="50px" alt="..">
                <h3>Business Process <br> Outsourcing</h3>
            </div>
        </div>
        <button type="button" class="btn_bred" routerLink="/about-whitelabel-nearshore">
            <div class="btn_fle">
                <div class="txt_fle">Learn more</div>
                <div class="wht_sha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta.svg" width="30px"
                        height="30px" alt="..."></div>
                <div class="redsha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta-white.svg" width="30px"
                        height="30px" alt="..."></div>
            </div>
        </button>
    </div>
    <div class="ban_lov">
        <button type="button" class="btn_bred" routerLink="/contact">
            <div class="btn_fle">
                <div class="txt_fle">Contact us</div>
                <div class="wht_sha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta.svg" width="30px"
                        height="30px" alt="..."></div>
                <div class="redsha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta-white.svg" width="30px"
                        height="30px" alt="..."></div>
            </div>
        </button>
        <img src="assets/imagenesWeb/Banners/banner_3.png" width="100%" alt="...">
    </div>
    <br><br><br>
</div>