<div class="di_ov">
  <div class="ban_abt">
    <div class="abt_txt">
      <h4>Get to know</h4>
      <h3>WHITE LABEL <br> NEARSHORE</h3>
      <p>We know that finding a formal nearshore company is a tough task, So that’s why our commitment to our partners
        is to deliver our services with a high sense of responsibility, customer transparency and strict adherence to
        deadlines while oriented
        toward results.</p>
    </div>
    <div class="img_abt"><img src="assets/imagenesWeb/About/a_header.png" width="100%" alt="..."></div>
  </div>
</div>
<h1 class="wdn-text-hidden"> Get to know White Label Nearshore</h1>

<br><br>
<div class="map_north">
  <div class="pic_map"><img src="assets/imagenesWeb/About/a_mapa Gt.png" width="55%" alt="..."></div>
  <div class="txt_map">
    <h5>WHO WE ARE</h5>
    <h2>Your <br> nearshore <br> partner</h2>
    <p>We are White-label Company based in Guatemala City, Central America, who would love to be your nearshore partner.
      We’d help your company to build a nearshore team in Guatemala City to scale your costs, revenue and offering.</p>
    <button type="button" class="btn_bred" routerLink="/contact">
      <div class="btn_fle">
        <div class="txt_fle">REQUEST A QUOTE</div>
        <div class="wht_sha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta-white.svg" width="30px" height="30px"
            alt="..."></div>
        <div class="redsha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta.svg" width="30px" height="30px"
            alt="..."></div>
      </div>
    </button>
    <br><br>
  </div>
</div>
<div class="hide_abt">
  <div class="abt">
    <div class="abt_txt">
      <h4>Our <span>values</span></h4>
      <h3>Responsability <br> <span>If we promise, we deliver.</span><br><br> Passion <br>
        <span>Our professionals loves the brands they work with, as much as their job</span><br><br> Collaboration <br>
        <span>Your company and our will become one.</span><br><br> Trustworthiness <br>
        <span>Eventhough the distance, we will safe and not share your business information.</span>
      </h3>
    </div>
    <div class="img_abt"><img src="assets/imagenesWeb/About/a_values.png" width="100%" alt="..."></div>
  </div>
</div>
<div class="our_lead d-none">
  <div class="txt-head-our">
    <h5>
      Our
    </h5>
    <h2>
      Leadership
    </h2>
    <p>
      Our leaders have a track record of delivering long-term, sustainable growth and results.
    </p>
  </div>
  <div class="card_fle ">
    <div class="card">
      <img src="assets/iconosWeb/desing_icons/Artboard 7.svg" class="card-img-top" alt="...">
      <div class="card-body">
        <h2>
          Lorem Ipsum
        </h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem consectetur suscipit assumenda
        </p>
        <div class="icons-cards">
          <div class="row">
            <div class="col"><img src="assets/iconosWeb/desing_icons/email.svg" alt="Icono WhiteLabel"></div>
            <div class="col"><img src="assets/iconosWeb/desing_icons/whatsapp.svg" alt="Icono WhiteLabel"></div>
            <div class="col"><img src="assets/iconosWeb/desing_icons/linkedin.svg" alt="Icono WhiteLabel"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <img src="assets/iconosWeb/desing_icons/Artboard 7.svg" class="card-img-top" alt="...">
      <div class="card-body">
        <h2>
          Lorem Ipsum
        </h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem consectetur suscipit assumenda
        </p>
        <div class="icons-cards">
          <div class="row">

            <div class="col"><img src="assets/iconosWeb/desing_icons/email.svg" alt="Icono WhiteLabel"></div>
            <div class="col"><img src="assets/iconosWeb/desing_icons/whatsapp.svg" alt="Icono WhiteLabel"></div>
            <div class="col"><img src="assets/iconosWeb/desing_icons/linkedin.svg" alt="Icono WhiteLabel"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <img src="assets/iconosWeb/desing_icons/Artboard 7.svg" class="card-img-top" alt="...">
      <div class="card-body">
        <h2>
          Lorem Ipsum
        </h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem consectetur suscipit assumenda
        </p>
        <div class="icons-cards">
          <div class="row">

            <div class="col"><img src="assets/iconosWeb/desing_icons/email.svg" alt="Icono WhiteLabel"></div>
            <div class="col"><img src="assets/iconosWeb/desing_icons/whatsapp.svg" alt="Icono WhiteLabel"></div>
            <div class="col"><img src="assets/iconosWeb/desing_icons/linkedin.svg" alt="Icono WhiteLabel"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <img src="assets/iconosWeb/desing_icons/Artboard 7.svg" class="card-img-top" alt="...">
      <div class="card-body">
        <h2>
          Lorem Ipsum
        </h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem consectetur suscipit assumenda
        </p>
        <div class="icons-cards">
          <div class="row">

            <div class="col"><img src="assets/iconosWeb/desing_icons/email.svg" alt="Icono WhiteLabel"></div>
            <div class="col"><img src="assets/iconosWeb/desing_icons/whatsapp.svg" alt="Icono WhiteLabel"></div>
            <div class="col"><img src="assets/iconosWeb/desing_icons/linkedin.svg" alt="Icono WhiteLabel"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<br>
<div class="col_ca">
  <br><br>
  <div class="tx_ca">
    <div class="tx_wht">
      <h2>What <br> clients <br> <span>say <br> about us</span> </h2>
    </div>

    <div id="abt_car_cards" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-inner">
        <!-- <div class="carousel-item ">
            <div class="card">
                <img src="assets/6522089_orig.jpg" class="card-img-top" alt="...">
                <div class="card-body">
                    <h4>“WLN delivers with accountability”</h4>
                    <hr class="h_card">
                    <h3>Vinnizio Rizo</h3>
                    <h5>Client Director</h5>
                </div>
            </div>
          </div> -->
        <div class="carousel-item active">
          <div class="card">
            <img src="assets/6522089_orig.jpg" class="card-img-top" alt="...">
            <div class="card-body">
              <h4>“I feel comfortable outsourcing all the requests of Mographs I receive from my Clients.”</h4>
              <hr class="h_card">
              <h3>Audiovisual Production</h3>
              <h5>Guatemala City, CA</h5>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="card">
            <img src="assets/6522089_orig.jpg" class="card-img-top" alt="...">
            <div class="card-body">
              <h4>“We've been very pleased with the level of service their nearshore agency has provided.”</h4>
              <hr class="h_card">
              <h3>Digital Agency</h3>
              <h5>Orlando FL, USA</h5>
            </div>
          </div>
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#abt_car_cards" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#abt_car_cards" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
  <br>
</div>
<div class="ban_lov">
  <button type="button" class="btn_bred" routerLink="/contact">
    <div class="btn_fle">
      <div class="txt_fle">Contact us</div>
      <div class="wht_sha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta.svg" width="30px" height="30px"
          alt="..."></div>
      <div class="redsha"><img src="assets/iconosWeb/WLNIconos/flecha-correcta-white.svg" width="30px" height="30px"
          alt="..."></div>
    </div>
  </button>
  <img src="assets/imagenesWeb/Banners/banner_3.png" width="100%" alt="...">
</div>
