<app-menu-wrapper></app-menu-wrapper>
<div class="ban_abt">
    <div class="abt_txt">
        <h3>White-Label <br> Development</h3>
        <p>Scale your costs and revenue <br> with a nearshore development team</p>
        <button type="button" class="btn_bred" routerLink="/contact">
            <div class="btn_fle"> <div class="txt_fle">Book a call</div>  <div class="wht_sha"><img  src="assets/iconosWeb/WLNIconos/flecha-correcta-white.svg" width="30px" height="30px" alt="..."></div> <div class="redsha"><img  src="assets/iconosWeb/WLNIconos/flecha-correcta.svg" width="30px" height="30px" alt="..."></div> </div>                            
        </button>
    </div>
    <div class="img_abt"><img src="assets/imagenesWeb/Service/Services - Development/s_header_development.png" width="100%" alt="..."></div>
</div>
<div class="hands_pic">
    <div class="ul_hand_pic">
        <h3 class="page-title">
            White-Label <br> Development
        </h3>
        <p class="display-text">
            We help your IT Department to build a nearshore team. Scale your cost and revenue with us. Send us the job requirements and we would seek and hire the right talent for your company.
            <br>
            <span>Our nearshore talent usually have:</span>

        </p>
        <ul>
            <li>
                <span>Technical English skills</span>
            </li>
            <li>
                <span>Engineer or technical degree</span>
            </li>
            <li><span>High sense of responsibility</span>
            </li>
            <li>
                <span>Tech savy and computer skills</span>
            </li>
            <li>
                <span>Full access to internet</span>
            </li>
        </ul>

        <button type="button" class="btn_bred" routerLink="/contact">
            <div class="btn_fle"> <div class="txt_fle">Request a quote</div>  <div class="wht_sha"><img  src="assets/iconosWeb/WLNIconos/flecha-correcta-white.svg" width="30px" height="30px" alt="..."></div> <div class="redsha"><img  src="assets/iconosWeb/WLNIconos/flecha-correcta.svg" width="30px" height="30px" alt="..."></div> </div>                            
        </button>
    </div>
    <img src="assets/imagenesWeb/Service/Services - Development/s_skill.png" width="100%" alt="...">

</div>
<div class="o_exp">
    <br>
    <h2>White-Label solutions <br> we offer to your company</h2>

    <div id="carouseldesk" class="carousel slide carousel-dark " data-bs-ride="carousel">
        <div class="carousel-inner">
            <div class="carousel-item active">
                <div class="card_fle">
                    <div class="card">
                        <div class="gro_wh">
                            <img src="assets/iconosWeb/WLNIconos/it staff augement - wln.svg" class="card-img-top" alt="...">
                        </div>
                        <div class="card-body">
                            <h4>IT Staff augmentation</h4>
                        </div>
                    </div>
                    <div class="card">
                        <div class="gro_wh">
                            <img src="assets/iconosWeb/WLNIconos/database - wln.svg" class="card-img-top" alt="...">
                        </div>
                        <div class="card-body">
                            <h4>Database Administrator DBA</h4>
                        </div>
                    </div>
                    <div class="card">
                        <div class="gro_wh">
                            <img src="assets/iconosWeb/WLNIconos/front back end - wln.svg" alt="..." />
                        </div>
                        <div class="card-body">
                            <h4>Webdesign and website development</h4>
                        </div>
                    </div>
                    <div class="card">
                        <div class="gro_wh">
                            <img src="assets/iconosWeb/WLNIconos/it technicians - wln.svg" alt="..." />
                        </div>
                        <div class="card-body">
                            <h4>IT Technicians - Helpdesk Support</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-item">
                <div class="card_fle">
                    <div class="card">
                        <div class="gro_wh">
                            <img src="assets/iconosWeb/WLNIconos/front back end devs - wln.svg" class="card-img-top" alt="...">
                        </div>
                        <div class="card-body">
                            <h4>Front-End/Back-End Devs</h4>
                        </div>
                    </div>
                    <div class="card">
                        <div class="gro_wh">
                            <img src="assets/iconosWeb/WLNIconos/infrastructure engineers - wln.svg" class="card-img-top" alt="...">
                        </div>
                        <div class="card-body">
                            <h4>Infrastructure Engineers</h4>
                        </div>
                    </div>
                    <div class="card">
                        <div class="gro_wh">
                            <img src="assets/iconosWeb/WLNIconos/cloud network engineers - wln.svg" alt="..." />
                        </div>
                        <div class="card-body">
                            <h4>Cloud/Network Engineers</h4>
                        </div>
                    </div>
                    <div class="card">
                        <div class="gro_wh">
                            <img src="assets/iconosWeb/WLNIconos/web apps dev - wln.svg" class="card-img-top" alt="...">
                        </div>
                        <div class="card-body">
                            <h4>Web/APPS developers</h4>
                        </div>
                    </div>
                    <div class="card">
                        <div class="gro_wh">
                            <img src="assets/iconosWeb/WLNIconos/back office - wln.svg" alt="..." />
                        </div>
                        <div class="card-body">
                            <h4>Back Office</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouseldesk" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouseldesk" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
    </div>

    <div id="carouselmob" class="carousel slide carousel-dark" data-bs-ride="carousel">
        <div class="carousel-inner">
            <div class="carousel-item active">
                <div class="card_fle">
                    <div class="card">
                        <div class="gro_wh">
                            <img src="assets/iconosWeb/WLNIconos/it staff augement - wln.svg" class="card-img-top" alt="...">
                        </div>
                        <div class="card-body">
                            <h4>IT Staff augmentation</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-item">
                <div class="card_fle">
                    <div class="card">
                        <div class="gro_wh">
                            <img src="assets/iconosWeb/WLNIconos/database - wln.svg" class="card-img-top" alt="...">
                        </div>
                        <div class="card-body">
                            <h4>Database Administrator DBA</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-item">
                <div class="card_fle">
                    <div class="card">
                        <div class="gro_wh">
                            <img src="assets/iconosWeb/WLNIconos/front back end - wln.svg" alt="..." />
                        </div>
                        <div class="card-body">
                            <h4>Webdesign and website development</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-item">
                <div class="card_fle">
                    <div class="card">
                        <div class="gro_wh">
                            <img src="assets/iconosWeb/WLNIconos/it technicians - wln.svg" alt="..." />
                        </div>
                        <div class="card-body">
                            <h4>IT Technicians - Helpdesk Support</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-item">
                <div class="card_fle">
                    <div class="card">
                        <div class="gro_wh">
                            <img src="assets/iconosWeb/WLNIconos/front back end devs - wln.svg" class="card-img-top" alt="...">
                        </div>
                        <div class="card-body">
                            <h4>Front-End/Back-End Devs</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-item">
                <div class="card_fle">
                    <div class="card">
                        <div class="gro_wh">
                            <img src="assets/iconosWeb/WLNIconos/infrastructure engineers - wln.svg" class="card-img-top" alt="...">
                        </div>
                        <div class="card-body">
                            <h4>Infrastructure Engineers</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-item">
                <div class="card_fle">
                    <div class="card">
                        <div class="gro_wh">
                            <img src="assets/iconosWeb/WLNIconos/cloud network engineers - wln.svg" alt="..." />
                        </div>
                        <div class="card-body">
                            <h4>Cloud/Network Engineers</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-item">
                <div class="card_fle">
                    <div class="card">
                        <div class="gro_wh">
                            <img src="assets/iconosWeb/WLNIconos/web apps dev - wln.svg" class="card-img-top" alt="...">
                        </div>
                        <div class="card-body">
                            <h4>Web/APPS developers</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-item">
                <div class="card_fle">
                    <div class="card">
                        <div class="gro_wh">
                            <img src="assets/iconosWeb/WLNIconos/back office - wln.svg" alt="..." />
                        </div>
                        <div class="card-body">
                            <h4>Back Office</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselmob" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselmob" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
    </div>


    <br><br>
    <p><span>*</span>All our services are delivered remotely from Guatemala, City Central America</p>
    <h2>Stack of experience</h2>
    <br><br>
    <div class="icons_fle">
        <div>
            <img src="assets/iconosWeb/LogosWeb/google-icon.svg" width="40px" height="40px" alt="...">
            <h3>Google</h3>
        </div>
        <div>
            <img src="assets/iconosWeb/LogosWeb/amazon.svg" width="40px" height="40px" alt="...">
            <h3>AWS</h3>
        </div>
        <div>
            <img src="assets/iconosWeb/LogosWeb/angular.svg" width="40px" height="40px" alt="...">
            <h3>Angular</h3>
        </div>
        <div>
            <img src="assets/iconosWeb/LogosWeb/react.svg" width="40px" height="40px" alt="...">
            <h3>React</h3>
        </div>
    </div>
    <div class="icons_fle">
        <div>
            <img src="assets/iconosWeb/LogosWeb/wordpress-blue.svg" width="40px" height="40px" alt="...">
            <h3>WordPress</h3>
        </div>
        <div>
            <img src="assets/iconosWeb/LogosWeb/joomla.svg" width="40px" height="40px" alt="...">
            <h3>Joomla</h3>
        </div>
        <div>
            <img src="assets/iconosWeb/LogosWeb/php-1.svg" width="40px" height="40px" alt="...">
            <h3>PHP</h3>
        </div>
        <div>
            <img src="assets/iconosWeb/LogosWeb/elementor.svg" width="40px" height="40px" alt="...">
            <h3>Elementor</h3>
        </div>
    </div>
    <div class="icons_fle">
        <div>
            <img src="assets/iconosWeb/LogosWeb/azure.svg" width="40px" height="40px" alt="...">
            <h3>Azure</h3>
        </div>
        <div>
            <img src="assets/iconosWeb/LogosWeb/javascript.svg" width="40px" height="40px" alt="...">
            <h3>Java Script</h3>
        </div>

    </div>
    <br><br><br><br>

</div>
<div class="icon_our">
    <br><br>
    <h1><span>Our </span> White-label <br> process</h1>
    <div class="icon_hom">
        <div class="txt_ima_ico">
            <img src="assets/iconosWeb/WLNIconos/the request - wln.svg" width="95px" height="95px" alt="...">
            <br>
            <h3> <span>We receive</span> the request <br> from your company</h3>
        </div>
        <div class="txt_ima_ico">
            <img src="assets/iconosWeb/WLNIconos/filter talent - wln.svg" width="95px" height="95px" alt="...">
            <br>
            <h3><span>We recruit, interview <br> and</span> filter talent for you</h3>
        </div>
        <div class="txt_ima_ico">
            <img src="assets/iconosWeb/WLNIconos/select talent - wln.svg" width="95px" height="95px" alt="...">
            <h3><span>You select the <br> nearshore talent</span> that <br> fills better the opening</h3>

        </div>
        <div class="txt_ima_ico">
            <img src="assets/iconosWeb/WLNIconos/we hire - wln.svg" width="95px" height="95px" alt="...">
            <h3><span>We hire, train and <br> manage</span> the nearshore <br> talent based on <br> Guatemala City</h3>

        </div>
    </div>
</div>
<div class="ban_lov">
    <button type="button" class="btn_bred" routerLink="/about-whitelabel-nearshore">
        <div class="btn_fle"> <div class="txt_fle">Learn more</div><div class="wht_sha"><img  src="assets/iconosWeb/WLNIconos/flecha-correcta.svg" width="30px" height="30px" alt="..."></div> <div class="redsha"><img  src="assets/iconosWeb/WLNIconos/flecha-correcta-white.svg" width="30px" height="30px" alt="..."></div> </div>                            
    </button>
    <img src="assets/imagenesWeb/Banners/banner_5.png" width="100%" alt="...">
</div>
<div class="team_txt">
    <div class="txt_team_lef">
        <h4>
            Why build <br> a nearshore
        </h4>
        <h3 class="page-title">
            development <br> team <br> <span>?</span>
        </h3>
    </div>
    <div class="ul_team">
        <p class="display-text">
            It has been proven that going nearshore provides you with great benefits such as scaling your costs and scaling your revenue, not to mention having a long-term nearshore partner. You’ll get the same quality that an onshore team can provide at an affordable
            cost.
            <br><br>
            <span>Our value proposition with your company is:</span>

        </p>
        <ul>
            <li>
                <span>Scale your offering</span>
            </li>
            <li>
                <span>Scale your costs</span>
            </li>
            <li><span>Scale your revenue</span>
            </li>
            <li>
                <span>Be you long-term nearshore partner</span>
            </li>
        </ul>
        <button type="button" class="btn_bred" routerLink="/contact">
            <div class="btn_fle"> <div class="txt_fle">Book a Call</div>  <div class="wht_sha"><img  src="assets/iconosWeb/WLNIconos/flecha-correcta-white.svg" width="30px" height="30px" alt="..."></div> <div class="redsha"><img  src="assets/iconosWeb/WLNIconos/flecha-correcta.svg" width="30px" height="30px" alt="..."></div> </div>                            
        </button>
    </div>
    <div class="pic_gosht">
        <img src="assets/imagenesWeb/Home/h_why.png" width="100%" alt="...">
    </div>


</div>
<div class="ban_glo">
    <button type="button" class="btn_bred" routerLink="/contact">
        <div class="btn_fle"> <div class="txt_fle">Contact us</div><div class="wht_sha"><img  src="assets/iconosWeb/WLNIconos/flecha-correcta.svg" width="30px" height="30px" alt="..."></div> <div class="redsha"><img  src="assets/iconosWeb/WLNIconos/flecha-correcta-white.svg" width="30px" height="30px" alt="..."></div> </div>                            
    </button>
    <img src="assets/imagenesWeb/Banners/banner_4.png" width="100%" alt="...">
</div>

<h1 class= "wdn-text-hidden"> White Label Nearshore Development</h1>