<div class="menu-color">
    <div class="wrapper">
        <div id="primer" class="item win-notac" routerLink="/socialMedia" routerLinkActive="win-ac">
            <a class="men-wra">
                <img class="icon-wh" src="assets/iconosWeb/WLNIconos/white_icon/social media - wln.svg" alt="..." />
                <img class="icon-rd" src="assets/iconosWeb/WLNIconos/red_icon/social media - wln.svg" alt="...">
                <br /> Social Media
            </a>
        </div>
        <div class="item win-notac" routerLink="/development" routerLinkActive="win-ac">
            <a>
                <img class="icon-wh" src="assets/iconosWeb/WLNIconos/white_icon/development - wln.svg" alt="..." />
                <img class="icon-rd" src="assets/iconosWeb/WLNIconos/red_icon/development - wln.svg" alt="...">
                <br /> Development
            </a>
        </div>
        <div class="item win-notac" routerLink="/backOffice" routerLinkActive="win-ac">
            <a>
                <img class="icon-wh" src="assets/iconosWeb/WLNIconos/white_icon/back office - wln.svg" alt="..." />
                <img class="icon-rd" src="assets/iconosWeb/WLNIconos/red_icon/back office - wln.svg" alt="...">
                <br /> Back Office
            </a>
        </div>
        <div class="item win-notac" routerLink="/graphicDesing" routerLinkActive="win-ac">
            <a>
                <img class="icon-wh" src="assets/iconosWeb/WLNIconos/white_icon/graphic design - wln.svg" style="transform: rotate(55deg);" alt="..." />
                <img class="icon-rd" src="assets/iconosWeb/WLNIconos/red_icon/graphic design - wln.svg" style="transform: rotate(55deg);" alt="...">
                <br /> Graphic Design
            </a>
        </div>
        <div class="item win-notac" routerLink="/photoVideo" routerLinkActive="win-ac">
            <a>
                <img class="icon-wh" src="assets/iconosWeb/WLNIconos/white_icon/photo video editing - wln.svg" alt="..." />
                <img class="icon-rd" src="assets/iconosWeb/WLNIconos/red_icon/photo video editing - wln.svg" alt="...">
                <br /> Photo/Video Editing
            </a>
        </div>
        <div class="item win-notac" routerLinkActive="win-ac">
            <a routerLink="/website">
                <img class="icon-wh" src="assets/iconosWeb/WLNIconos/white_icon/website building - wln.svg" alt="..." />
                <img class="icon-rd" src="assets/iconosWeb/WLNIconos/red_icon/website building - wln.svg" alt="...">
                <br /> Website building
            </a>
        </div>
        <div class="item win-notac" routerLinkActive="win-ac">
            <a routerLink="/wcag">
                <img class="icon-wh" src="assets/iconosWeb/WLNIconos/white_icon/icon-acc.svg" alt="..." />
                <img class="icon-rd" src="assets/iconosWeb/WLNIconos/red_icon/icon-acc.svg" alt="...">
                <br /> WCAG QA
            </a>
        </div>
    </div>
</div>